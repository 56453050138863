<template>
  <div>
    <load-profile />
    <!-- Search Container Card -->
    <n-search-container
      v-model="params"
      :active="true"
      :show-header="false"
      show-search
      :loading="loading"
      @search="search"
      @reset="reset"
    >
      <n-search-input
        ref="search"
        v-model="params"
        :fields="searchFields"
      />
    </n-search-container>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col
            v-if="$can('create', 'player') && !userData.isVendor"
            class="text-right"
          >
            <b-button
              variant="primary"
              :to="{ name: 'create-player' }"
            >{{ $t("button.create") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          @sort-changed="sortChanged"
        >
          <template #cell(ipaddress)="data">
            <b-link
              class="link-underline"
              @click="viewIp(data.item)"
            >
              {{ data.item.ipaddress }}
            </b-link>
          </template>
          <template #cell(profilePicture)="data">
            <b-avatar
              size="40"
              :src="data.item.profilePicture"
              :text="avatarText(data.item.name)"
              variant="light-primary"
              class="badge-minimal"
            >
              <feather-icon
                v-if="!data.item.name"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </template>
          <template #cell(givenCredit)="data">
            {{ data.item.givenCredit | currency(data.item.ccy) }}
          </template>
          <template #cell(avalBalance)="data">
            {{ data.item.avalBalance | currency(data.item.ccy) }}
          </template>
          <template #cell(cashBalance)="data">
            {{ data.item.cashBalance | currency(data.item.ccy) }}
          </template>
          <template #cell(isVendor)="data">
            <b-badge
              v-if="data.item.isVendor"
              pill
              variant="info"
              badge-glow
            >
              {{ $t(`general.external`) }}
            </b-badge>
            <b-badge
              v-else
              pill
              variant="primary"
              badge-glow
            >
              {{ $t(`general.internal`) }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.isEnable"
              pill
              variant="success"
              badge-glow
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge
              v-else
              pill
              variant="danger"
              badge-glow
            >
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(lastActivityAt)="data">
            {{ data.item.lastActivityAt | formatDate }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', 'player')"
                v-b-tooltip.hover
                :to="{
                  name: 'update-player',
                  params: { id: data.item.id },
                }"
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('create', 'player') &&
                  !data.item.isVendor"
                v-b-tooltip.hover
                :to="{
                  name: 'create-player',
                  query: { id: data.item.id },
                }"
                :title="$t('button.clone')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
              <b-button
                v-if="
                  ($can('create', 'player') || $can('update', 'player')) &&
                    !data.item.isVendor
                "
                v-b-tooltip.hover
                :title="$t('button.deposit')"
                variant="primary"
                pill
                size="sm"
                @click="
                  showDepositOrWithdrawModel(
                    data.item.id,
                    data.item.username,
                    data.item.avalBalance,
                    data.item.ccy
                  )
                "
              >
                <feather-icon icon="CreditCardIcon" />
              </b-button>
              <b-button
                v-if="($can('create', 'player') || $can('update', 'player')) &&
                  !data.item.isVendor"
                v-b-tooltip.hover
                :title="$t('button.withdraw')"
                variant="primary"
                pill
                size="sm"
                @click="
                  showDepositOrWithdrawModel(
                    data.item.id,
                    data.item.username,
                    data.item.avalBalance,
                    data.item.ccy,
                    'withdrawal'
                  )
                "
              >
                <feather-icon icon="RotateCcwIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        />
      </div>
    </b-card>
    <deposit-or-withdraw
      ref="depositWithdrawModel"
      @success="depositOrWithdrawSuccess"
    />
    <ip-modal ref="ipModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BModal,
  BRow,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import NInput from '@/components/NInput'
import DepositOrWithdraw from '@/views/account/depositOrWithdraw'
import { avatarText } from '@core/utils/filter'
import IpModal from '@/views/ipModal/Index.vue'
import SearchInputs from './searchInput'
import TableFields from './tableFields'

const PlayerRepository = Repository.get('player')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BForm,
    BFormGroup,
    BInputGroup,
    BBadge,
    BModal,
    BLink,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    NInput,
    DepositOrWithdraw,
    IpModal,
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || '',
        parentId: this.$route.query.parentId || '',
      },
      avatarText,
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.name
        || !!this.$route.query.username
        || !!this.$route.query.isEnable
        || !!this.$route.query.parentId
      )
    },
    userData() {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem('userData'))
    },
    userId() {
      if (this.$store.state.profile.id) {
        return this.$store.state.profile.id
      }

      return this.userData?.id
    },
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  mounted() {
    this.getData(this.params.page)
  },
  methods: {
    viewIp(item) {
      this.$refs.ipModal.show(item)
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData(this.params.page)
    },
    search(searchText) {
      this.params.search = searchText
      this.params.page = 1
      this.$refs.pagination.reset()
      this.updateQuerySting()
      this.getData(this.params.page)
    },
    reset() {
      this.$refs.search.reset()
      this.params.search = ''
      this.params.page = 1
      this.$refs.pagination.reset()
      this.updateQuerySting()
      this.getData(this.page)
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex(item => item.id === id)

      this.items.splice(deleteIndex, 1)
      this.total -= 1
    },
    deleteItem(id) {
      PlayerRepository.delete(id)
        .then(response => {
          this.spliceItem(id)
        })
        .catch(error => {})
        .then(() => {
          this.loading = false
        })
    },
    getData() {
      this.loading = true
      PlayerRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then(response => {
          const data = response.data.data.list
          this.items = [...data]
          this.total = response.data.data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    showDepositOrWithdrawModel(
      id,
      username,
      availableCredit,
      ccy,
      type = 'deposit',
    ) {
      this.$refs.depositWithdrawModel.changeData({
        type,
        username,
        amount: 0,
        userId: id,
        remark: null,
        availableCredit,
        ccy,
      })

      this.$refs.depositWithdrawModel.show(
        `${this.$t('field.deposit')} (${username})`,
      )
    },
    depositOrWithdrawSuccess() {
      this.getData()
    },
  },
  setup() {
    const fields = [...TableFields]
    const searchFields = [...SearchInputs]

    return {
      fields,
      searchFields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
